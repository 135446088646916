import { Link } from 'gatsby';
// import fetch from 'node-fetch';
import React, { FC } from 'react';

import { RaccourcisEl } from './styles';

const Raccourcis: FC<{
  user: firebase.default.firestore.DocumentSnapshot;
}> = ({ user }) => (
  <RaccourcisEl>
    {('admin' === user.get('type') ||
      (user.get('profils') &&
        user.get('profils').includes('boutique-catalogue'))) && (
      <Link to="/boutique/produits/en-vente/">
        <div className="icon">
          <span className="icon-produits" />
        </div>
        <span>Produits</span>
      </Link>
    )}

    {('admin' === user.get('type') ||
      (user.get('profils') &&
        user.get('profils').includes('boutique-contacts'))) && (
      <Link to="/boutique/contacts">
        <div className="icon">
          <span className="icon-contacts" />
        </div>
        <span>Contacts</span>
      </Link>
    )}

    {('admin' === user.get('type') ||
      (user.get('profils') &&
        user.get('profils').includes('website-pages'))) && (
      <Link to="/website/pages">
        <div className="icon">
          <span className="icon-pages" />
        </div>
        <span>Pages</span>
      </Link>
    )}

    {('admin' === user.get('type') ||
      (user.get('profils') &&
        user.get('profils').includes('boutique-commandes'))) && (
      <Link to="/boutique/commandes/">
        <div className="icon">
          <span className="icon-commandes" />
        </div>
        <span>Commandes</span>
      </Link>
    )}

    {('admin' === user.get('type') ||
      (user.get('profils') &&
        user.get('profils').includes('website-articles'))) && (
      <Link to="/website/articles">
        <div className="icon">
          <span className="icon-articles" />
        </div>
        <span>Articles</span>
      </Link>
    )}

    {('admin' === user.get('type') ||
      (user.get('profils') &&
        user.get('profils').includes('website-memos'))) && (
      <Link to="/website/memos">
        <div className="icon">
          <span className="icon-memos" />
        </div>
        <span>Mémos</span>
      </Link>
    )}
  </RaccourcisEl>
);

export default Raccourcis;
