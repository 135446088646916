import fetch from 'node-fetch';
import React, { FC } from 'react';

import Button from '../Button';

const Deployment: FC<{
  boutique: firebase.default.firestore.DocumentSnapshot;
  user: firebase.default.firestore.DocumentSnapshot;
}> = ({ boutique, user }) => {
  const handleDeployWWWOnClick = () => {
    const token = boutique.get('token');
    const workflow = boutique.get('workflow');
    const repo = boutique.get('repo');

    fetch(
      `https://api.github.com/repos/${repo}/actions/workflows/${workflow}/dispatches`,
      {
        body: JSON.stringify({
          ref: 'master',
        }),
        headers: {
          Accept: 'application/vnd.github.v3+json',
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    )
      .then(() => alert('Lancement du déployement réussi'))
      .catch(error => console.error(error.message));
  };

  return (
    <div>
      {('admin' === user.get('type') ||
        (user.get('profils') &&
          user.get('profils').includes('deploy-www'))) && (
        <Button onClick={handleDeployWWWOnClick}>
          <span>Déployer</span>
        </Button>
      )}
    </div>
  );
};

export default Deployment;
