import React, { FC } from 'react';

import Deployment from '../../../../components/Deployment';
// import MessageByContacts from '../../../../components/MessageByContacts';
import Raccourcis from '../../../../components/Raccourcis';
import Content from '../../../../styles/Content';
import requireBoutique, {
  BoutiqueProps,
} from '../../../../utils/requireBoutique';

const Home: FC<BoutiqueProps> = ({ boutique, user }) => (
  <Content>
    <h1>Bienvenue dans votre espace administrateur</h1>
    <h2>Raccourci</h2>
    {user && <Raccourcis user={user} />}
    <h2>Déploiement</h2>
    {user && <Deployment boutique={boutique} user={user} />}
    {/* <MessageByContacts boutique={boutique} /> */}
  </Content>
);

export default requireBoutique(Home);
