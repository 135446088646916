import styled from 'styled-components';

import addressBook from '../../images/font-awesome/solid/address-book.svg';
import book from '../../images/font-awesome/solid/book.svg';
import commentAlt from '../../images/font-awesome/solid/comment-alt.svg';
import newspaper from '../../images/font-awesome/solid/newspaper.svg';
import quoteRight from '../../images/font-awesome/solid/quote-right.svg';
import receipt from '../../images/font-awesome/solid/receipt.svg';
import shoppingBag from '../../images/font-awesome/solid/shopping-bag.svg';

// eslint-disable-next-line import/prefer-default-export
export const RaccourcisEl = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    margin: 0 1.5rem 1.5rem 0;
    font-size: 14px;

    &:nth-child(2n) {
      margin-right: ${props => (props.theme.mobile ? 0 : '1.5rem')};
    }
    text-align: center;
    text-decoration: none;
    color: inherit;
    .icon {
      background: #fff;
      width: 60px;
      height: 60px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.375rem;
      border: 1px solid #e4e7ed;

      span {
        mask-image: url(${shoppingBag});
        //background-color: #273a8a;
        background-color: #86848c;
        mask-repeat: no-repeat;
        width: 30px;
        height: 30px;
        display: inline-block;
        mask-position: center;

        &.icon-pages {
          mask-image: url(${book});
        }

        &.icon-contacts {
          mask-image: url(${addressBook});
        }

        &.icon-commandes {
          mask-image: url(${receipt});
        }

        &.icon-demandes {
          mask-image: url(${commentAlt});
        }

        &.icon-articles {
          mask-image: url(${newspaper});
        }

        &.icon-memos {
          mask-image: url(${quoteRight});
        }
      }
    }
  }
`;
