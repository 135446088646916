import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../core/sysext/CMS/containers/Backoffice';
import Home from '../core/sysext/CMS/containers/Home';

const DashboardPage: FC<PageProps> = props => {
  const { location } = props;

  return (
    <Backoffice location={location}>
      <Home />
    </Backoffice>
  );
};

export default DashboardPage;
